import { useEffect } from 'react';
import type { ReactNode } from 'react';

import { fetchGuestInfo, setAppOrgCode, setIsAppReady } from '../components/updateApp/appStatusSlice';
import { getDidOnboarding, isNative } from '../helpers/device.helper';
import { getAreas, getLanguageList, saveArea } from '../pages/guest/guestSlice';
import { useAppDispatch, useAppSelector } from '../store';

export const OrgCodeProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const firstArea = useAppSelector((state) => state.guestSlice.areas)[0];
  const isAppReady = useAppSelector((state) => state.appStatusSlice.isAppReady);

  useEffect(() => {
    dispatch(getAreas());
    dispatch(getLanguageList());
  }, [dispatch]);

  useEffect(() => {
    const initializeOrgCode = async () => {
      if (isAppReady) return;

      if (!isNative() || getDidOnboarding()) {
        const response = await dispatch(fetchGuestInfo());
        if (fetchGuestInfo.fulfilled.match(response) && response.payload) {
          const guestInfo = response.payload;
          dispatch(setAppOrgCode(guestInfo.org_code));
          dispatch(setIsAppReady(true));
        }
      } else {
        if (firstArea) {
          await dispatch(saveArea({ new_org_code: firstArea.code }));
          dispatch(setAppOrgCode(firstArea.code));
          dispatch(setIsAppReady(true));
        }
      }
    };

    initializeOrgCode();
  }, [dispatch, firstArea, isAppReady]);

  if (!isAppReady) {
    return null;
  }

  return <>{children}</>;
};
