import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { type Org } from '../../helpers/device.helper';
import type { LabelGroup } from '../../models/advocate';
import type { ErrorMessage } from '../../models/Error';
import type { Document, MoreInfoPages } from '../../models/guest';
import { DocumentType } from '../../models/guest';
import type { Language } from '../../models/language';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

type GuestPreferenceIds = {
  label_ids: string[];
};

export type OrgDetails = {
  code: Org;
  name: string;
};

type GuestSliceType = {
  languageList: Language[];
  searchLabelGroups: LabelGroup[];
  preferenceOptions: LabelGroup[];
  guestLabelIds: string[];
  guestIsBlocked: boolean | undefined;
  privacyNoticeDocument: Document | undefined;
  rulesAndTermsDocument: Document | undefined;
  areas: OrgDetails[];
  showMoreInfo: Record<MoreInfoPages, boolean>;
};

const initialState: GuestSliceType = {
  languageList: [],
  searchLabelGroups: [],
  preferenceOptions: [],
  guestLabelIds: [],
  guestIsBlocked: undefined,
  privacyNoticeDocument: undefined,
  rulesAndTermsDocument: undefined,
  areas: [],
  showMoreInfo: {
    leps: false,
    services: false,
    area_select: false,
    language_select: false,
    first_alerts: false,
  },
};

export const getLanguageList = createAsyncThunk<Language[], undefined, AsyncThunkConfig>(
  'guest/getLanguageList',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Language[]>('/guest/v1_get_languages');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getLanguageList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getSearchLabels = createAsyncThunk<LabelGroup[], undefined, AsyncThunkConfig>(
  'guest/getSearchLabels',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<LabelGroup[]>('/guest/v0_get_search_labels');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getSearchLabels']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAreas = createAsyncThunk<OrgDetails[], undefined, AsyncThunkConfig>(
  'guest/getAreas',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<OrgDetails[]>('/guest/v0_get_areas');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getAreas']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type SaveAreaParams = { new_org_code: Org; selected_language_code?: string };
type SaveAreResponse = { saved: boolean; is_valid_language: boolean };
export const saveArea = createAsyncThunk<SaveAreResponse, SaveAreaParams, AsyncThunkConfig>(
  'guest/saveArea',
  async (params, thunkAPI) => {
    try {
      const response = await api.post<SaveAreResponse>('/guest/v0_save_area', params);
      if (response?.saved) {
        await thunkAPI.dispatch(getLanguageList());
      }
      return response ?? { saved: false, is_valid_language: false };
    } catch (e) {
      logError(e, ['guestSlice', 'saveArea']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getPreferenceOptions = createAsyncThunk<LabelGroup[], undefined, AsyncThunkConfig>(
  'guest/getPreferenceOptions',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<LabelGroup[]>('/guest/v0_get_preference_options');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getPreferenceOptions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getPreferences = createAsyncThunk<GuestPreferenceIds, undefined, AsyncThunkConfig>(
  'guest/getPreferences',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<GuestPreferenceIds>('/guest/v0_get_preferences');
      return response ?? { label_ids: [] };
    } catch (e) {
      logError(e, ['guestSlice', 'getPreferenceOptions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const savePreferences = createAsyncThunk<boolean, string[], AsyncThunkConfig>(
  'guest/savePreferences',
  async (labelIds, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_save_preferences', { label_ids: labelIds });
      return response ?? false;
    } catch (e) {
      logError(e, ['guestSlice', 'savePreferences']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchDocuments = createAsyncThunk<Document[] | undefined, string, AsyncThunkConfig>(
  'guest/fetchDocuments',
  async (language_code, thunkAPI) => {
    try {
      const response = await api.post<Document[] | undefined>('/guest/v0_get_latest_documents', { language_code });
      return response;
    } catch (e) {
      logError(e, ['guestSlice', 'fetchDocuments']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestBlockedStatus = createAsyncThunk<boolean | undefined, undefined, AsyncThunkConfig>(
  'guest/getGuestBlockedStatus',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_get_blocked_status');
      return response;
    } catch (e) {
      logError(e, ['guestSlice', 'getGuestBlockedStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestBlockedStatus: (state, action: PayloadAction<boolean>) => {
      state.guestIsBlocked = action.payload;
    },
    setGuestLabelIds: (state, action: PayloadAction<string[]>) => {
      state.guestLabelIds = action.payload;
    },
    setShowMoreInfo: (state, action: PayloadAction<MoreInfoPages>) => {
      state.showMoreInfo[action.payload] = !state.showMoreInfo[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLanguageList.fulfilled, (state, action) => {
        state.languageList = action.payload;
      })
      .addCase(getSearchLabels.fulfilled, (state, action) => {
        state.searchLabelGroups = action.payload;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.areas = action.payload;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.guestLabelIds = action.payload.label_ids;
      })
      .addCase(getPreferenceOptions.fulfilled, (state, action) => {
        state.preferenceOptions = action.payload;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        action.payload?.forEach((document) => {
          if (document.document_type === DocumentType.PRIVACY_POLICY) {
            state.privacyNoticeDocument = document;
          } else {
            state.rulesAndTermsDocument = document;
          }
        });
      })
      .addCase(getGuestBlockedStatus.fulfilled, (state, action) => {
        state.guestIsBlocked = action.payload;
      });
  },
});

export const { setGuestBlockedStatus, setGuestLabelIds, setShowMoreInfo } = guestSlice.actions;
