import { useState } from 'react';
import { chevronDownOutline } from 'ionicons/icons';
import type React from 'react';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { IonIcon, IonRow } from '@ionic/react';

import './TSSelect.scss';

import type { ButtonVariant } from '../TSButton/TSButton';
import { TSButton } from '../TSButton/TSButton';
import type { TSInputProps } from '../TSInput/TSInput';
import { TSInput } from '../TSInput/TSInput';
import type { TSDrawerProps } from '../TSModal/TSDrawer';
import { TSDrawer } from '../TSModal/TSDrawer';

interface TSSelectProps {
  placeholder: string;
  modalContent: ReactElement;
  titleContent?: ReactElement;
  onChange?: (selectedValues: string[]) => void;
  onCancel?: VoidFunction;
  label?: string;
  value?: string;
  heightPercentage?: number;
  buttonVariant?: ButtonVariant;
  drawerProps?: Partial<TSDrawerProps>;
  inputProps?: Partial<TSInputProps>;
}

export const TSSelect: React.FC<TSSelectProps> = ({
  modalContent,
  titleContent,
  placeholder,
  label,
  value,
  heightPercentage = 75,
  buttonVariant = 'red',
  drawerProps,
  inputProps,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <>
      <TSDrawer
        heightPercentage={heightPercentage}
        isOpen={drawerIsOpen}
        closeModal={() => setDrawerIsOpen(false)}
        titleContent={titleContent}
        mainContent={modalContent}
        footerContent={
          <IonRow className="ion-margin ion-justify-content-between">
            <TSButton
              variant="underline"
              onClick={() => {
                onCancel?.();
                setDrawerIsOpen(false);
              }}
            >
              {t('generic.cancel')}
            </TSButton>
            <TSButton variant={buttonVariant} onClick={() => setDrawerIsOpen(false)} id="done">
              {t('generic.done')}
            </TSButton>
          </IonRow>
        }
        {...drawerProps}
      />
      <TSInput
        label={label}
        placeholder={placeholder}
        value={value}
        readonly
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => setDrawerIsOpen(true)}
        {...inputProps}
      >
        <IonIcon slot="end" icon={chevronDownOutline} />
      </TSInput>
    </>
  );
};
