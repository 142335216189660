import type { Org } from '../helpers/device.helper';
import type { LabelTKey } from '../i18n';

export type Advocate = {
  advocate_id: string;
  available: boolean;
  name: string;
  img_url: string | null;
  in_chat: boolean;
  org_name: string;
  org_code: Org;

  /** Only for LEPS */
  spoken_languages?: LabelTKey[];
  /** Only for LEPS */
  community_names?: string[];
  /** Only for LEPS */
  lived_experiences?: LabelTKey[];
  /** Only for TBW */
  lived_experiences_ids?: string[];
  /** Only for SP */
  service_provider_organization_id?: string;
  /** Only for SP */
  service_provider_organization_name?: string;
  /** Only for SP */
  service_provider_title?: string;
  /** Only for SP */
  service_title?: string;
  /** Only for SP */
  service_description?: string;
};

export class Profile {
  first_name: string | null = null;
  last_name: string | null = null;
  nickname: string | null = null;
  email = '';
  label_ids: string[] = [];
  labels: string[] = [];
  id = '';
  img_url = '';
  // Service provider
  service_provider_organization_id?: string;
  service_provider_title?: string | null = null;
  service_title?: string | null = null;
  service_description?: string | null = null;
  service_provider_organization_name?: string | null = null;
}

export type Label = {
  alias: LabelTKey;
  id: string;
  name: string;
  order: number | null;
  org_code?: Org;
  indigenous_name?: string;
};

export type LabelGroup = {
  tab_alias: string | null;
  group_alias: LabelTKey;
  group_order: number;
  required: boolean | null;
  labels: Label[];
};

export class AdvocatePostChatSurvey {
  thread_id: string | null = null;
  end_reason_id: string | null = null;
  comments: string | null = null;
  referred_guest: boolean | null = null;
  subject_ids: string[] = [];
  other_subject: string | null = null;
  is_training = false;
  abuse_rating: number | null = null;
}

export type SessionLog = {
  month: number | undefined;
  year: number | undefined;
};

export type SessionLogResponse = {
  advocate_post_chat_survey_complete: boolean;
  community_ids: string[];
  community_names: string[];
  email: string;
  end_timestamp: string;
  first_name: string;
  id: string;
  last_name: string;
  start_timestamp: string;
  trigger_timestamp: string;
  thread_id: string;
  time_expired: boolean;
};

export type AdvocateHoursResponse = {
  time_online: string;
};
