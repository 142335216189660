import { useState } from 'react';
import { chevronDown, chevronUp } from 'ionicons/icons';

import { IonCheckbox, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';

import './TSList.scss';

interface CheckboxChangeEventDetail<T> {
  value: T;
  checked: boolean;
}

interface CheckboxCustomEvent<T> extends CustomEvent {
  detail: CheckboxChangeEventDetail<T>;
  target: HTMLIonCheckboxElement;
}

type TSListProps<T> = {
  list: T[] | undefined;
  selectedItems: T[] | undefined;
  onSelectionChange: (selectedItems: T[], e?: CheckboxCustomEvent<CheckboxChangeEventDetail<T>>) => void;
  className?: string;
  flexibleList?: {
    moreLabel: string;
    lessLabel: string;
  };
  children: (item: T) => React.ReactNode;
} & (T extends object ? { itemKeyProperty: keyof T } : { itemKeyProperty?: undefined });

export const MultiSelect = <T,>({
  list = [],
  selectedItems = [],
  onSelectionChange,
  itemKeyProperty,
  className = '',
  flexibleList,
  children,
}: TSListProps<T>): React.ReactElement => {
  const getItemKeyProperty = (item: T) => (itemKeyProperty ? item[itemKeyProperty] : item);
  const isSelected = (item: T) =>
    selectedItems.some((selectedItem) => getItemKeyProperty(selectedItem) === getItemKeyProperty(item));
  const [showMore, setShowMore] = useState(false);
  const trimmedList = !flexibleList || showMore ? list : list.slice(0, 3);

  return (
    <IonList mode="ios" lines="none" className={`multi-select white-bg ${className}`}>
      {trimmedList.map((item, index) => {
        const key = itemKeyProperty ? (item[itemKeyProperty] as React.Key) : index;

        return (
          <IonItem mode="ios" key={key}>
            <IonCheckbox
              mode="ios"
              value={getItemKeyProperty(item)}
              checked={isSelected(item)}
              onIonChange={(e) => {
                const newSelectedItems = e.detail.checked
                  ? [...selectedItems, item]
                  : selectedItems.filter(
                      (selectedItem) => getItemKeyProperty(selectedItem) !== getItemKeyProperty(item),
                    );
                onSelectionChange(newSelectedItems, e);
              }}
            >
              <IonLabel className="title-medium ion-text-wrap">{children(item)}</IonLabel>
            </IonCheckbox>
          </IonItem>
        );
      })}
      {flexibleList && list.length > 3 && (
        <IonItem className="flexible-list-item" onClick={() => setShowMore(!showMore)}>
          <IonLabel>
            {showMore ? flexibleList.lessLabel : flexibleList.moreLabel}
            <IonIcon icon={showMore ? chevronUp : chevronDown}></IonIcon>
          </IonLabel>
        </IonItem>
      )}
    </IonList>
  );
};
