import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { environment } from '../../environment/environment';
import { isBurns } from '../../helpers/device.helper';
import type { Advocate, Label } from '../../models/advocate';
import { GUEST_COMPONENT } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import { FilterModalStatus } from '../../models/guest';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

type LepsSliceType = {
  lepsPageStatus: GUEST_COMPONENT;
  lepsAdvocates: Advocate[];
  externalLepsAdvocate: Advocate[];
  selectedLepsAdvocate: Advocate | undefined;
  lepsSelectedCommunities: Label[];
  lepsSelectedLanguages: Label[];
  lepsFilterModalStatus: FilterModalStatus;
  searchLepsAdvocatesLoading: boolean;
  showLepsTabBar: boolean;
  showAccordionContent: boolean;
};

const initialState: LepsSliceType = {
  lepsPageStatus: GUEST_COMPONENT.ADVOCATE_SELECT,
  selectedLepsAdvocate: undefined,
  lepsSelectedCommunities: [],
  lepsSelectedLanguages: [],
  lepsAdvocates: [],
  externalLepsAdvocate: [],
  lepsFilterModalStatus: FilterModalStatus.IDLE,
  searchLepsAdvocatesLoading: false,
  showLepsTabBar: true,
  showAccordionContent: false,
};

export type FindAdvocatesBody = {
  for_service_providers: boolean;
  label_ids: string[];
  elm_app_major_version: string;
  elm_app_version: string;
};

type SearchLepsAdvocate = { internalAdvocates: Advocate[]; externalAdvocates: Advocate[] };

export const searchLepsAdvocates = createAsyncThunk<SearchLepsAdvocate, undefined, AsyncThunkConfig>(
  'leps/searchLepsAdvocates',
  async (_, thunkAPI) => {
    try {
      const searchEndpoint = isBurns ? '/guest/v2_search_burns_advocates' : '/guest/v2_search_advocates';
      const appOrgCode = thunkAPI.getState().appStatusSlice.appOrgCode;
      const { lepsSelectedCommunities, lepsSelectedLanguages } = thunkAPI.getState().lepsSlice;
      const body: FindAdvocatesBody = isBurns
        ? {
            label_ids: thunkAPI.getState().guestSlice.guestLabelIds,
            for_service_providers: false,
            elm_app_major_version: environment.versionNumber.split('.')[0],
            elm_app_version: environment.versionNumber,
          }
        : {
            label_ids: [...lepsSelectedCommunities.map((c) => c.id), ...lepsSelectedLanguages.map((l) => l.id)],
            for_service_providers: false,
            elm_app_major_version: environment.versionNumber.split('.')[0],
            elm_app_version: environment.versionNumber,
          };

      const response = await api.post<Advocate[]>(searchEndpoint, body);
      return {
        internalAdvocates: response?.filter((advocate) => advocate.org_code === appOrgCode) ?? [],
        externalAdvocates: response?.filter((advocate) => advocate.org_code !== appOrgCode) ?? [],
      };
    } catch (e) {
      logError(e, ['lepsSlice', 'searchLepsAdvocates']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const lepsSlice = createSlice({
  name: 'leps',
  initialState,
  reducers: {
    setLepsPageStatus: (state, action: PayloadAction<GUEST_COMPONENT>) => {
      state.lepsPageStatus = action.payload;
    },
    setSelectedLepsAdvocate: (state, action: PayloadAction<Advocate | undefined>) => {
      state.selectedLepsAdvocate = action.payload;
    },
    setLepsSelectedCommunityList: (state, action: PayloadAction<Label[]>) => {
      state.lepsSelectedCommunities = action.payload;
    },
    setLepsSelectedLanguageList: (state, action: PayloadAction<Label[]>) => {
      state.lepsSelectedLanguages = action.payload;
    },
    setLepsFilterModalStatus: (state, action: PayloadAction<FilterModalStatus>) => {
      state.lepsFilterModalStatus = action.payload;
    },
    setShowLepsTabBar: (state, action: PayloadAction<boolean>) => {
      state.showLepsTabBar = action.payload;
    },
    setShowAccordionContent: (state, action: PayloadAction<boolean>) => {
      state.showAccordionContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchLepsAdvocates.pending, (state, _action) => {
        state.searchLepsAdvocatesLoading = true;
      })
      .addCase(searchLepsAdvocates.fulfilled, (state, action) => {
        state.lepsAdvocates = action.payload.internalAdvocates;
        state.externalLepsAdvocate = action.payload.externalAdvocates;
        state.searchLepsAdvocatesLoading = false;
      });
  },
});

export const {
  setLepsPageStatus,
  setSelectedLepsAdvocate,
  setLepsSelectedCommunityList,
  setLepsSelectedLanguageList,
  setLepsFilterModalStatus,
  setShowLepsTabBar,
  setShowAccordionContent,
} = lepsSlice.actions;
