/* Core CSS required for Ionic components to work properly */
import { lazy, Suspense } from 'react';

import { setIconOptions } from '@fluentui/react/lib/Styling';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.scss';
import './theme/common.scss';
import './App.scss';

// Support for <= iOS 15 (PM-7561)
import 'core-js/stable/array/to-reversed';

import AppUrlListener from './AppUrlListener';
import { ErrorToast } from './components/ErrorToast/ErrorToast';
import { APP_MODAL } from './components/updateApp/appStatusSlice';
import { isNative } from './helpers/device.helper';
import { history } from './history';
import { useInitApp } from './hooks/useInitApp';
import { MiddlewareRouter } from './routes/Middleware.router';
import { RaygunErrorHandlerService } from './services/raygun';
import { useAppSelector } from './store';

setIconOptions({ disableWarnings: true });
setupIonicReact({ innerHTMLTemplatesEnabled: true, swipeBackEnabled: false, hardwareBackButton: false });
RaygunErrorHandlerService().setRaygunUser();

const NotificationPermissionModal = lazy(() => import('./components/Permissions/NotificationPermissionModal'));
const AndroidExplainerModal = lazy(() => import('./components/Permissions/AndroidExplainerModal'));
const AppStatusModal = lazy(() => import('./components/updateApp/AppStatusModal'));
const FAOnboarding = lazy(() => import('./components/FirstAlerts/FAOnboarding'));
const FirstAlertModal = lazy(() => import('./components/FirstAlerts/modals/FirstAlertModal'));
const FirstAlertDrawer = lazy(() => import('./components/FirstAlerts/modals/FirstAlertDrawer'));
const EvolvingMessageModal = lazy(() => import('./components/updateApp/EvolvingMessageModal'));
const CommunityNotFoundModal = lazy(() => import('./components/FirstAlerts/modals/CommunityNotFoundModal'));

const App = () => {
  useInitApp();
  const isAppModalUpdate = useAppSelector((state) => state.appStatusSlice.appModal === APP_MODAL.UPDATE);
  const appUpdateDismissed = useAppSelector((state) => state.appStatusSlice.appUpdateDismissed);
  const showAppUpdate = isAppModalUpdate && !appUpdateDismissed;

  return (
    <IonApp>
      <IonReactRouter history={history}>
        <Suspense>
          {isNative() && (
            <>
              <AppStatusModal />
              <FirstAlertModal />
              {!showAppUpdate && (
                <>
                  <NotificationPermissionModal />
                  <AndroidExplainerModal />
                  <EvolvingMessageModal />
                  <FAOnboarding />
                  <CommunityNotFoundModal />
                  <FirstAlertDrawer />
                </>
              )}
            </>
          )}
        </Suspense>
        <AppUrlListener />
        <ErrorToast />
        {!showAppUpdate && <MiddlewareRouter />}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
