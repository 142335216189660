import dayjs from 'dayjs';

export const saveNotNowAskForFirstAlertsStartDate = () => {
  localStorage.setItem('not-now-ask-for-first-alert-date', dayjs().format('YYYY-MM-DD'));
};

export const getAskForFirstAlertsStartModal = () => {
  const date = localStorage.getItem('not-now-ask-for-first-alert-date');
  if (!date) {
    return true;
  } else {
    return dayjs(date).isBefore(dayjs().add(-3, 'months'));
  }
};

export const saveAskForFirstAlertsCommunityDate = () => {
  localStorage.setItem('ask-for-first-alert-community-date', dayjs().toISOString());
};

export const getAskForFirstAlertsCommunityModal = () => {
  const dateStr = localStorage.getItem('ask-for-first-alert-community-date');
  if (dateStr) {
    // Using toISOString again to handle dates saved before in the 'YYYY-MM-DD' format
    return dayjs(dateStr).toISOString();
  }
};

export const formatAlertTimestamp = (isDart: boolean, timestamp: string | undefined) => {
  if (!timestamp) return '';
  const sentAt = dayjs(timestamp);
  const today = dayjs();

  if (isDart) {
    if (today.isSame(sentAt, 'day')) {
      return 'Today, ' + sentAt.format('h:mm A');
    }
    return sentAt.format('dddd, h:mm A');
  } else {
    const diffHours = today.diff(sentAt, 'hours');

    if (diffHours < 1) return 'Just now';
    if (diffHours < 4) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    if (today.isSame(sentAt, 'day')) return 'Today';
    if (today.subtract(1, 'day').isSame(sentAt, 'day')) return 'Yesterday';

    return sentAt.format('dddd, MMMM DD, YYYY');
  }
};
