import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { getInitialSessionLog, groupByDay } from '../../helpers/advocate.helper';
import type { AdvocateHoursResponse, Label, LabelGroup, SessionLog, SessionLogResponse } from '../../models/advocate';
import { Profile } from '../../models/advocate';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/Error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

export type SessionLogFormated = {
  date: string;
  sessionResponse: SessionLogResponse[];
};

type AdvocateAccountSliceType = {
  profileApiStatus: APIStatus;
  sessionApiStatus: APIStatus;
  profile: Profile;
  editProfile: Profile;
  currentComponent: ACCOUNT_COMPONENT;
  labelGroups: LabelGroup[];
  communityLabels: Label[];
  languageLabels: Label[];
  livedExperienceLabels: Label[];
  advocateHours: AdvocateHoursResponse[];
  sessionLogFormated: SessionLogFormated[];
  sessionResponse: SessionLogResponse[];
  isSessionLogSurvey: boolean;
  sessionLog: SessionLog;
};

export enum ACCOUNT_COMPONENT {
  PROFILE = 'profile',
  EDIT_PROFILE = 'editProfile',
  LIVED_EXPERIENCES = 'livedExperiences',
  CHANGE_PASSWORD = 'changePassword',
  SESSION_LOG = 'sessionLog',
}

const initialState: AdvocateAccountSliceType = {
  profileApiStatus: APIStatus.IDLE,
  sessionApiStatus: APIStatus.IDLE,
  profile: new Profile(),
  editProfile: new Profile(),
  currentComponent: ACCOUNT_COMPONENT.PROFILE,
  labelGroups: [],
  communityLabels: [],
  languageLabels: [],
  livedExperienceLabels: [],
  advocateHours: [],
  sessionLogFormated: [],
  sessionResponse: [],
  isSessionLogSurvey: false,
  sessionLog: getInitialSessionLog(),
};

export const getAdvocateProfile = createAsyncThunk<Profile, undefined, AsyncThunkConfig>(
  'advocateAccount/getAdvocateProfile',
  async (_, thunkAPI) => {
    try {
      const isServiceProvider = thunkAPI.getState().advocateAuthSlice.currentUser?.service_provider_organization_id;
      const endpoint = isServiceProvider ? 'v0_service_provider_profile' : 'v0_leps_profile';
      const response = await api.get<Profile>(`/advocate/${endpoint}`);
      return response ?? new Profile();
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateProfile']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getLabelGroups = createAsyncThunk<LabelGroup[], undefined, AsyncThunkConfig>(
  'advocateAccount/getLabelGroups',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<LabelGroup[]>('/advocate/v1_get_labels');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getLabelGroups']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAdvocateProfile = createAsyncThunk<boolean, Profile, AsyncThunkConfig>(
  'advocateAccount/setAdvocateProfile',
  async (profile, thunkAPI) => {
    try {
      const isServiceProvider = thunkAPI.getState().advocateAuthSlice.currentUser?.service_provider_organization_id;
      const endpoint = isServiceProvider ? 'v0_service_provider_profile_update' : 'v0_leps_profile_update';
      const response = await api.post<boolean>(`/advocate/${endpoint}`, profile);
      return response ?? false;
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'setAdvocateProfile']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateSessionLog = createAsyncThunk<SessionLogResponse[], SessionLog, AsyncThunkConfig>(
  'advocateAccount/getAdvocateSessionLog',
  async (sessionLogs, thunkAPI) => {
    try {
      const response = await api.post<SessionLogResponse[]>('/advocate/v0_get_chat_logs', sessionLogs);
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateSessionLog']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateHours = createAsyncThunk<AdvocateHoursResponse[], SessionLog, AsyncThunkConfig>(
  'advocateAccount/getAdvocateHours',
  async (sessionLogs, thunkAPI) => {
    try {
      const response = await api.post<AdvocateHoursResponse[]>('/advocate/v0_get_advocate_hours', sessionLogs);
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateHours']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateAccountSlice = createSlice({
  name: 'advocateAccount',
  initialState,
  reducers: {
    setCurrentComponent: (state, action: PayloadAction<ACCOUNT_COMPONENT>) => {
      state.currentComponent = action.payload;
    },
    setSessionLogsSurvey: (state, action: PayloadAction<boolean>) => {
      state.isSessionLogSurvey = action.payload;
    },
    setSessionLog: (state, action: PayloadAction<SessionLog>) => {
      state.sessionLog = action.payload;
    },
    updateEditProfile: (state, action: PayloadAction<Omit<Partial<Profile>, 'label_ids'>>) => {
      state.editProfile = { ...state.editProfile, ...action.payload };
    },
    updateEditProfileLabelIds: (
      state,
      action: PayloadAction<{ group_alias: LabelGroup['group_alias']; label_ids: string[] }>,
    ) => {
      const groupLabelIds = state.labelGroups
        .find((lg) => lg.group_alias === action.payload.group_alias)
        ?.labels.map((l) => l.id);
      const otherGroupLabelIds = state.editProfile.label_ids.filter((id) => !groupLabelIds?.includes(id));
      state.editProfile.label_ids = [...otherGroupLabelIds, ...action.payload.label_ids];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvocateProfile.pending, (state, _action) => {
        state.profileApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateProfile.rejected, (state, _action) => {
        state.profileApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.editProfile = action.payload;
        state.profileApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getLabelGroups.fulfilled, (state, action) => {
        state.labelGroups = action.payload;
        state.communityLabels = action.payload.find((l) => l.group_alias === 'communities')?.labels ?? [];
        state.languageLabels = action.payload.find((l) => l.group_alias === 'languages')?.labels ?? [];
        state.livedExperienceLabels = action.payload.find((l) => l.group_alias === 'livedExperiences')?.labels ?? [];
      })
      .addCase(getAdvocateSessionLog.pending, (state, _action) => {
        state.sessionApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateSessionLog.rejected, (state, _action) => {
        state.sessionApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateSessionLog.fulfilled, (state, action) => {
        state.sessionResponse = action.payload;
        state.sessionLogFormated = groupByDay(action.payload);
        state.sessionApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getAdvocateHours.pending, (state, _action) => {
        state.sessionApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateHours.rejected, (state, _action) => {
        state.sessionApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateHours.fulfilled, (state, action) => {
        state.advocateHours = action.payload;
        state.sessionApiStatus = APIStatus.FULFILLED;
      });
  },
});

export const {
  setCurrentComponent,
  setSessionLogsSurvey,
  setSessionLog,
  updateEditProfile,
  updateEditProfileLabelIds,
} = advocateAccountSlice.actions;
